import PricingCard from "../PricingCard"
import * as React from "react"
import Button from "../../button"
import classnames from "../../../helpers/classnames"

const CaseStudiesPricingCard = ({buttonId = "case-studies-buy", href, ...rest}) => {
  return (
    <PricingCard
      title={<>Case Studies<br/>(Part 3)</>}
      footer={
        <Button
          id={buttonId}
          width="200px"
          target="_blank"
          href={href}
          className={classnames("mx-auto mt-0")}

        >ENROLL NOW</Button>
      }
      {...rest}
    >
      <ul>
        <li><b>1h15 video lectures</b><br/>(English & Portuguese subs) </li>
        <li><b>11 case studies</b></li>
      </ul>
    </PricingCard>
  )
}

export default CaseStudiesPricingCard