import React from "react"
import PropTypes from "prop-types"

import styles from "./list.module.scss"
import classnames from "../../helpers/classnames"

function listStyle(type) {
  switch (type) {
    case "circle":
      return styles.circleItem
    case "triangle":
      return styles.triangleItem
    case "number":
      return styles.numberItem
    default:
      return styles.circleItem
  }
}

const List = ({ children, type, className, ...rest }) => {
  const L = type === "number" ? "ol" : "ul";
  return (
    <L className={classnames(className, styles.list)}>
      {children.map((item, i) => (
        <li className={listStyle(type)} key={`ztp${i}`}>
          {item}
        </li>
      ))}
    </L>
  )
}

List.propTypes = {
  children: PropTypes.array.isRequired,
}

export default List
