import React from "react"
import { ExperiencePage } from "./experience"
import { useStaticQuery, graphql } from "gatsby"
import LecturesOnePricingCard from "../components/pricing/pricing-cards/LecturesOnePricingCard"
import LecturesTwoPricingCard from "../components/pricing/pricing-cards/LecturesTwoPricingCard"
import CaseStudiesPricingCard from "../components/pricing/pricing-cards/CaseStudiesPricingCard"
import Complete from "../components/pricing/pricing-cards/Complete"
import PricingWrapper from "../components/pricing/PricingWrapper"
import CompleteCard from "../components/pricing/pricing-cards/Complete"
import classnames from "../helpers/classnames"
import List from "../components/list"
import { scrollById } from "../utils/scroller"

const Page = ({ location }) => {
  const siteMetadata = useStaticQuery(query).site.siteMetadata
  const { b } = siteMetadata.pricingConfiguration

  const scrollToChapter = (e, chapterN) => {
    e.preventDefault()

  }

  return (
    <>
      <ExperiencePage
        pricingHref="#masterclass-options-section"
        location={location}
        b_pricing_text={
          <div className={classnames("mx-auto")} style={{ maxWidth: "674px" }}>
            <p>You can either enroll in the&nbsp;<b>Complete Masterclass</b>&nbsp;or
              in one of the three parts which constitute it.</p>

            <List type="circle" className={classnames("my-16")}>
            <span><b>Lectures #1:</b> includes chapters 1, 2 and 3 (see <a className={classnames("underline")} target="_blank" href={`/b/#masterclass-content-chapter-1`}>here</a>)</span>
              <span><b>Lectures #2:</b> includes chapters 4, 5 and 6 (see <a className={classnames("underline")} target="_blank" href={`/b/#masterclass-content-chapter-4`}>here</a>)</span>
              <span><b>Case studies:</b> includes chapter 7 (see <a className={classnames("underline")} target="_blank" href={`/b/#masterclass-content-chapter-7`}>here</a>)</span>
            </List>

            <p>
              It’s OK to enroll part by part. We just recommend that you start
              with Lectures #1, then Lectures #2 and finish with Case Studies.
            </p>
          </div>
        }
        pricing={
          <PricingWrapper>
            <LecturesOnePricingCard
              big
              moneyBackGuarantee
              oldPrice={b.lectures1.oldPrice}
              price={b.lectures1.price}
              href={b.lectures1.url}
            />

            <LecturesTwoPricingCard
              big
              moneyBackGuarantee
              oldPrice={b.lectures2.oldPrice}
              price={b.lectures2.price}
              href={b.lectures2.url}
            />

            <CaseStudiesPricingCard
              big
              moneyBackGuarantee
              oldPrice={b.caseStudies.oldPrice}
              price={b.caseStudies.price}
              href={b.caseStudies.url}
            />

            <CompleteCard
              bVersion
              moneyBackGuarantee
              id="pricing-complete-masterclass"
              oldPrice={b.complete.oldPrice}
              price={b.complete.price}
              href={b.complete.url}
            />

          </PricingWrapper>
        }
        checkoutUrls={b}
      />
    </>
  )
}

const query = graphql`
query ExperienceB {
  site {
    siteMetadata {
      pricingConfiguration {
        b {
          lectures1
          {
            price
            oldPrice
            url
          }
          lectures2
          {
            price
            oldPrice
            url
          }
          caseStudies
          {
            price
            oldPrice
            url
          }
          complete
          {
            price
            oldPrice
            url
          }
        }
      }
    }
  }
}
`


export default Page
