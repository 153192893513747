import PricingCard from "../PricingCard"
import * as React from "react"
import Button from "../../button"
import classnames from "../../../helpers/classnames"

const LecturesTwoPricingCard = ({buttonId = "lectures-2-buy", href, ...rest}) => {
  return (
    <PricingCard
      title={<>Lectures #2<br/>(Part 2)</>}
      footer={
        <Button
          id={buttonId}
          width="200px"
          target="_blank"
          href={href}
          className={classnames("mx-auto mt-0")}

        >ENROLL NOW</Button>
      }
      {...rest}
    >
      <ul>
        <li><b>3h30 video lectures</b><br/>(English & Portuguese subs) </li>
        <li><b>9 eBooks</b><br/>(in total 300+ pages)</li>
        <li><b>Ideation prompts</b></li>
      </ul>
    </PricingCard>
  )
}

export default LecturesTwoPricingCard