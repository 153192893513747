import PricingCard from "../PricingCard"
import * as React from "react"
import Button from "../../button"
import classnames from "../../../helpers/classnames"

const LecturesOnePricingCard = ({buttonId = "lectures-1-buy", href, ...rest}) => {
  return (
    <PricingCard
      title={<>Lectures #1<br/>(Part 1)</>}
      footer={
        <Button
          id={buttonId}
          width="200px"
          target="_blank"
          href={href}
          className={classnames("mx-auto mt-0")}

        >ENROLL NOW</Button>
      }
      {...rest}
    >
      <ul>
        <li><b>3h15 video lectures</b><br/>(English & Portuguese subs) </li>
        <li><b>5 eBooks</b><br/>(in total 150+ pages)</li>
        <li><b>Ideation prompts</b></li>
      </ul>
    </PricingCard>
  )
}

export default LecturesOnePricingCard